import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["userStatus", "getOnboardingStatus"])
  },
  methods: {
    async checkUserStatus() {
      await this.$store.dispatch("getUserStatus");
      if (this.userStatus) {
        this.getEnrolledCourses();
      }
      this.checkOnboardingStatus();
    },
    checkOnboardingStatus() {
      let local_onboarding_pending = localStorage.getItem("onboardingStatus");

      if (
        local_onboarding_pending === null ||
        local_onboarding_pending === undefined
      ) {
        this.$store.dispatch("getOnboardingPendingStatus").then(() => {
          this.redirectDashboard(this.userStatus, this.getOnboardingStatus);
        });
      } else {
        if (this.$route.name === "Loading") {
          this.redirectDashboard(
            this.userStatus,
            JSON.parse(local_onboarding_pending)
          );
        }
      }
    },
    redirectDashboard(uStatus, oPending) {
      if (uStatus === false && oPending === false) {
        if (this.$route.name !== "Courses Detail") {
          this.$router.push({ name: "Dashboard" });
        }
      } else {
        this.$router.push({ name: "Onboarding" });
      }
    },
    getEnrolledCourses() {
      // New User will update the onboarding information
      // in background, we'll register the new user
      // in the LMS system, the below API will internally
      // create a new user if the user doesn't exist
      this.$store.dispatch("getEnrolledCourses");
    }
  }
};
